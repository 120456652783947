import { useTranslation } from 'next-i18next'
import React from 'react'
import Reviews from 'src/components/Reviews'

const ReviewsWrapper = () => {
  const { t } = useTranslation('landing')
  const REVIEWS = t('INDEX_REVIEW.REVIEWS', { returnObjects: true })
  return (
    <Reviews
      title={t('INDEX_REVIEW.HEADING')}
      subtitle={t('INDEX_REVIEW.SUBHEADING')}
      reviews={REVIEWS}
    />
  )
}

export default React.memo(ReviewsWrapper)
