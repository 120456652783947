import StarIcon from '@mui/icons-material/Star'
import Image from 'next/image'
import React from 'react'
import { IReview } from 'src/screens/Host/types'
import QuotesLeftIcon from '../../../public/svgs/quote-left.svg'
import { Colors } from '../../constants/theme'

type IProps = {
  review: IReview
  hideStars?: boolean
}

const ReviewCard = ({ review, hideStars }: IProps) => {
  return (
    <div className="mb-[35px] flex size-[320px] flex-col overflow-hidden rounded-[30px] bg-blue-500 p-5 px-10 py-[30px] text-white-500 sm:size-[350px] md:h-[385px] md:w-[380px]">
      <div className="relative flex items-center justify-between">
        <div className="flex flex-1 flex-col">
          <Image
            src={QuotesLeftIcon}
            alt="quotes"
            height={50}
            width={50}
            className="mb-2 ml-[5px] hidden sm:block"
          />
          <Image
            src={QuotesLeftIcon}
            alt="quotes"
            height={43}
            width={43}
            className="block sm:hidden"
          />

          <div className="flex flex-col sm:mb-3">
            <div>
              {!hideStars &&
                [...Array(5)].map((_, i) => (
                  <StarIcon
                    key={i}
                    fontSize="small"
                    sx={{ color: Colors.yellowStar }}
                  />
                ))}
            </div>
            <p className="text-[15px] font-normal sm:mt-2">
              {review.name.toUpperCase()}
            </p>
          </div>
        </div>
        <div className="absolute right-[-21px] top-[-21px] flex size-[72px] overflow-hidden rounded-b-full rounded-tl-full sm:right-[-42px] sm:top-[-32px] sm:size-[100px]">
          <Image
            alt={review.name}
            src={review?.url ?? '/images/empty.jpeg'}
            fill
            sizes="(max-width: 768px) 30vw,
                     15vw"
            style={{ objectFit: 'cover' }}
            loading="eager"
          />
        </div>
      </div>
      <div className="flex h-full flex-col justify-between md:w-full">
        <p className="pr-[50px] text-[15px] font-normal sm:pr-[58px]">
          {review.text}
        </p>

        <div className="flex">
          <p className="text-[15px] font-normal">
            {review.lugar.toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ReviewCard)
