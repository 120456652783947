import React from 'react'
import { IReview } from 'src/screens/Host/types'
import Carousel from '../Carousel/Carousel'
import ReviewCard from './ReviewCard'

type IProps = {
  title: string
  subtitle: string
  reviews: IReview[]
  hideStars?: boolean
}

const Reviews = ({ title, subtitle, reviews, hideStars }: IProps) => {
  return (
    <section className="flex w-full justify-center overflow-hidden md-lg:flex-row">
      <div className="relative mx-auto flex w-full max-w-[1832px] flex-col items-center justify-center lg:flex-row lg:items-start lg:justify-start lg:px-7 xl:px-12">
        <div className="w-full max-w-md justify-center  lg:h-[385px] lg:w-[461px] lg:justify-start">
          <p className="px-5 py-0 text-center text-[32px] font-semibold leading-[37px] text-black-400 lg:mt-[70px] lg:p-0 lg:text-start lg:text-[32px] lg:leading-[42px] ">
            {title}
          </p>
          <p className="my-2 px-[30px] py-0 text-center text-[15px] font-normal leading-[19px] text-black-400 lg:my-5 lg:p-0 lg:text-start lg:leading-[20px]">
            {subtitle}
          </p>
        </div>
        <div className="relative mt-11 w-full pb-6 lg:absolute lg:left-[45%] lg:mt-0 xl:left-[40%]">
          <Carousel
            carouselOptions={{ loop: true, align: 'start' }}
            carouselOptionsMobile={{ align: 'center' }}
            items={reviews}
            showButtons
            showDots
          >
            {({ item }) => (
              <ReviewCard review={item as IReview} hideStars={hideStars} />
            )}
          </Carousel>
        </div>
      </div>
    </section>
  )
}

export default React.memo(Reviews)
